import React from 'react';
import useUser from '../store/selectors/useUser';
import useApiPost from '../api/useApiPost';
import { sortAscending } from '../utils/helpers';

const buildFormData = ({ uId }) => {
  let formData = new FormData();
  formData.set('hID', 'getContacts');
  formData.set('uID', uId);
  return formData;
};

const mapResponseToData = response => response?.data?.result ?? [];

export default function useContacts({ autoLoad = true } = {}) {
  const { id: uId } = useUser();

  const { data, handlePost, loading, error, setState } = useApiPost({ mapResponseToData });
  const handleRefresh = React.useCallback(() => handlePost({ data: buildFormData({ uId }) }), [uId, handlePost]);
  
  React.useEffect(() => {
    autoLoad && handleRefresh();
  }, [autoLoad, handleRefresh]);

  const setContacts = React.useCallback(contacts => setState(prev => ({ ...prev, data: contacts })), [setState]);

  return { data: data.sort(sortAscending('id')), loading, error, handleRefresh, setContacts };
}
